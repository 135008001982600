import { PropsWithChildren, ReactNode, useMemo } from 'react';

import { Box, Flex, Heading, Link } from '@chakra-ui/react';
import { ArrowRightSmallIcon } from '@udacity/chakra-uds-icons';

import { Card } from '~/components/card';
import { Carousel, CarouselProps } from '~/components/carousel';
import { CatalogCard, CatalogCardSkeleton } from '~/features/catalog/components/catalog-card';
import { CatalogItem } from '~/features/catalog/models/catalog-item';
import { Subscription } from '~/features/payment/models/subscription';

import { CatalogCardItem } from '../models/catalog-card-item';

export function CatalogCardCarousel(
  props: {
    catalogItems?: (CatalogItem | CatalogCardItem | { title: string; key: string })[];
    defaultCarouselItems?: ReactNode[];
    isLoading?: boolean;
    inCard?: boolean;
    slidesPerView?: 'auto' | number;
    carouselTheme?: 'light' | 'dark';
    flexMode?: boolean;
    defaultWidth?: string;
    buttonContext?: string;
    onCardClick?: (catalogItem: CatalogItem | CatalogCardItem) => void;
    onNextOrPrevClick?: () => void;
    subscription?: Subscription | null;
  } & Omit<CarouselProps, 'items'>
) {
  const {
    catalogItems,
    defaultCarouselItems,
    isLoading,
    inCard,
    slidesPerView,
    carouselTheme,
    flexMode,
    defaultWidth = '173.333px',
    buttonContext,
    onCardClick,
    onNextOrPrevClick,
    subscription,
    ...carouselProps
  } = props;

  const carouselItems = useMemo(() => {
    if (defaultCarouselItems) return defaultCarouselItems;

    if (isLoading || !catalogItems) {
      return [...Array(10).keys()].map((i) => (
        <CatalogCardSkeleton key={i} width={{ base: '190px', xl: defaultWidth }} />
      ));
    }

    return catalogItems?.map((catalogItem) => {
      if ('slug' in catalogItem) {
        return (
          <CatalogCard
            key={catalogItem.key}
            catalogItem={catalogItem}
            grow={Boolean(flexMode)}
            subscription={subscription}
            width={{ base: '190px', xl: defaultWidth }}
            onClick={() => onCardClick?.(catalogItem)}
          />
        );
      } else {
        return (
          <CatalogCard
            key={catalogItem.key}
            grow={Boolean(flexMode)}
            width={{ base: '190px', xl: defaultWidth }}
            basicItem={{
              title: catalogItem.title,
              key: catalogItem.key,
            }}
          />
        );
      }
    });
  }, [catalogItems, defaultCarouselItems, defaultWidth, flexMode, isLoading, onCardClick, subscription]);

  return (
    <WithCard inCard={inCard}>
      {!flexMode && (
        <Carousel
          buttonContext={buttonContext}
          items={carouselItems ?? []}
          slidesPerView={{ base: 'auto', '2xl': slidesPerView ?? 6 }}
          theme={carouselTheme}
          onNextClick={onNextOrPrevClick}
          onPrevClick={onNextOrPrevClick}
          {...carouselProps}
        />
      )}

      {flexMode && (
        <Box padding={{ base: '0 16px', lg: '0 24px', xl: '0' }}>
          <Heading as={props.titleAs} mb='16px' size={props.titleSize}>
            {props.title}
          </Heading>
          <Flex gap='16px' overflowX={{ base: 'auto', xl: 'hidden' }} overflowY='hidden'>
            {carouselItems ?? []}
          </Flex>
          {props.linkHref && props.linkText && (
            <>
              <Box h='16px' />
              <Link _visited={{ color: 'blue.500' }} href={props.linkHref} paddingX='16px' width='fit-content'>
                {props.linkText} <ArrowRightSmallIcon color='inherit' h='32px' w='32px' />
              </Link>
            </>
          )}
        </Box>
      )}
    </WithCard>
  );
}

function WithCard({ inCard, children }: PropsWithChildren<{ inCard?: boolean }>) {
  return inCard ? (
    <Card
      borderLeftRadius={{ base: 'none', md: 'md' }}
      borderLeftWidth={{ base: '0', md: '1px' }}
      borderRightRadius={{ base: 'none', md: 'md' }}
      borderRightWidth={{ base: '0', md: '1px' }}
      overflow='hidden'
      padding={{ base: '24px 0', xl: '24px' }}
    >
      {children}
    </Card>
  ) : (
    <Box>{children}</Box>
  );
}
